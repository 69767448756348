//global
import React, { Ref, useContext, useEffect, useRef, useState } from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import _ from 'lodash';
//lib
import ComponentContext from 'lib/context/ComponentContext';
//local
import {
  MyAccountDetailsProps,
  allValuesAreEmpty,
} from 'components/MyAccountDetails/MyAccountDetails';
import { transformData } from 'src/utils/formUtils';
import { Form as FormikForm, Formik, FormikValues, FormikProps, FormikHelpers } from 'formik';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import TextAreaField from 'src/helpers/Form/TextAreaField';
import useDictionary from 'src/hooks/useDictionary';

// import { useOcDispatch } from 'src/redux/ocStore';
// import { updateUser } from 'src/redux/ocUser';
import { Me } from 'ordercloud-javascript-sdk';
import Loader from 'components/Loader/Loader';
import { myAccountDetailsTailwindVariant } from 'tailwindVariants/components/myAccountDetailsTailwindVariant';
import { updateUser } from 'src/redux/ocUser';
import { useOcDispatch } from 'src/redux/ocStore';

//type
export type MyAccountSpecialInstructionsProps = MyAccountDetailsProps;
type FormValuesTypes = {
  AdditionalDeliveryAddress?: string;
  SpecialPickupInformation?: string;
};
//main component
const MyAccountSpecialInstructions: React.FC<MyAccountSpecialInstructionsProps> = ({
  fields,
  params,
}) => {
  const {
    base,
    headigWrapper,
    textLabel,
    smallLabel,
    content,
    editingContent,
    inlineFields,
    informationContainer,
    fieldWrapper,
    userInfo,
    linkText,
    submitBtn,
    cancelBtn,
    textLabelBold,
    loaderWrapper,
    textLabelMedium,
    textareaWrapper,
  } = myAccountDetailsTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  // transforming the form field data
  const transFormFields = transformData(fields.data?.data?.formFields);
  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  // const dispatch = useOcDispatch();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [fieldValues, setFieldValues] = useState<FormValuesTypes>();
  const [userId, setUserId] = useState('');
  const [formInitialValues, setFormInitialValues] = useState<FormValuesTypes>();
  const formRef = useRef<FormikHelpers<FormValuesTypes>>(null);
  const activeSection = fields?.data?.data?.title?.value;
  const initialValues: FormValuesTypes = {
    AdditionalDeliveryAddress: formInitialValues?.AdditionalDeliveryAddress ?? '',
    SpecialPickupInformation: formInitialValues?.SpecialPickupInformation ?? '',
  };
  /**chacking the initial values if all values are empty or not */
  const isValuesEmpty = allValuesAreEmpty(initialValues);
  //fetching initial values.
  const loadInitialData = async () => {
    setLoading(true);
    const userDetails = await Me.Get();
    setUserId(userDetails?.ID);
    setFormInitialValues({
      AdditionalDeliveryAddress: userDetails?.xp?.DeliveryInstruction ?? '',
      SpecialPickupInformation: userDetails?.xp?.PickupInstruction ?? '',
    });
    setLoading(false);
  };
  //set form initail values
  useEffect(() => {
    loadInitialData();
  }, []);
  //load initial data
  const handleCollapse = (title: string) => {
    setcomponentContextData({
      ...componentContextData,
      isEqualFields: true,
    });
    componentContextData?.isEqualFields
      ? setcomponentContextData({ ...componentContextData, collapse: title, isEqualFields: true })
      : setcomponentContextData({ ...componentContextData, collapse: title, isEqualFields: false });
  };
  //setting field values
  const setValues = (values: FormValuesTypes) => {
    setFieldValues({
      ...values,
    });
  };
  //compering the current data with new data
  useEffect(() => {
    const isEqual = _.isEqual(fieldValues, formInitialValues);
    isEqual
      ? setcomponentContextData({ ...componentContextData, isEqualFields: true })
      : setcomponentContextData({ ...componentContextData, isEqualFields: false });
  }, [fieldValues]);
  //handling accordian
  useEffect(() => {
    const item = document?.querySelectorAll('.myAccountDetails');
    item.forEach((item) => {
      const content = item.querySelector<HTMLElement>('.instructionsContent');
      const form = item.querySelector<HTMLElement>('.instructionsEditContent');
      if (item.classList.contains('open')) {
        if (componentContextData?.collapse === fields?.data?.data?.title?.value) {
          form ? (form.style.maxHeight = '1300px') : '';
          content ? (content.style.maxHeight = '0px') : '';
        } else {
          form ? (form.style.maxHeight = `0px`) : '';
          content ? (content.style.maxHeight = `100%`) : '';
        }
      }
    });
  }, [componentContextData?.collapse]);

  const dispatch = useOcDispatch();

  //handling submit action
  const submitFormData = async (values: FormikValues) => {
    try {
      setSaveLoading(true);
      const updateUserDetails = {
        ID: userId,
        xp: {
          DeliveryInstruction: values?.AdditionalDeliveryAddress,
          PickupInstruction: values?.SpecialPickupInformation,
        },
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await dispatch(updateUser(updateUserDetails));
      loadInitialData();
      setcomponentContextData({ ...componentContextData, isEqualFields: true, collapse: null });
      setSaveLoading(false);
    } catch (error) {
      console.error('@specialInstructionError: ', error);
    }
  };
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;

  return (
    <div className={base({ className: params?.Style + ' open' })}>
      <div className={content({ className: 'instructionsContent' })}>
        <div className={headigWrapper()}>
          <TextHelper tag="p" field={fields?.data?.data?.title} className={textLabelMedium()} />
          {/**TODO: replace with dynamic text */}
          <button
            aria-label="add/edit"
            aria-controls="button"
            className={linkText()}
            onClick={() => {
              formRef?.current?.resetForm();
              handleCollapse(activeSection);
              !componentContextData?.isEqualFields &&
                setcomponentContextData({
                  ...componentContextData,
                  isWarningPopUp: true,
                  passwordChangeSuccess: false,
                  currentSection: activeSection,
                });
            }}
          >
            {isValuesEmpty ? getDictionaryValue('AddCTA') : getDictionaryValue('EditCTA')}
          </button>
        </div>
        {!isValuesEmpty && (
          <div
            className={userInfo({
              className: textareaWrapper(),
            })}
          >
            {formInitialValues?.AdditionalDeliveryAddress && (
              <p className={textLabel()}>
                {getDictionaryValue('DeliveryInstructions')} &nbsp;
                {formInitialValues?.AdditionalDeliveryAddress}
              </p>
            )}
            {formInitialValues?.SpecialPickupInformation && (
              <p className={textLabel()}>
                {getDictionaryValue('PickupInstructions')}
                &nbsp;
                {formInitialValues?.SpecialPickupInformation}
              </p>
            )}
          </div>
        )}
      </div>
      <div
        className={editingContent({
          className: `instructionsEditContent ${
            componentContextData?.collapse === fields?.data?.data?.title?.value ? '' : '!py-0'
          }`,
        })}
      >
        <TextHelper tag="p" field={fields?.data?.data?.title} className={textLabelBold()} />
        <TextHelper tag="p" field={fields?.data?.data?.shortDescription} className={smallLabel()} />
        {!loading && (
          <Formik
            initialValues={initialValues}
            innerRef={formRef as Ref<FormikProps<FormValuesTypes>>}
            onSubmit={(values: FormValuesTypes) => {
              submitFormData(values);
            }}
          >
            {({ resetForm }) => (
              <FormikForm className={informationContainer()}>
                <div className={inlineFields()}>
                  {transFormFields?.AdditionalDeliveryAddress && (
                    <div
                      className={fieldWrapper({
                        className: textareaWrapper(),
                      })}
                    >
                      <TextAreaField
                        {...transFormFields?.AdditionalDeliveryAddress}
                        setFieldValue={setValues}
                        className="[&>*>span]:!hidden"
                      />
                      <TextHelper
                        tag={'p'}
                        field={{
                          value: transFormFields?.AdditionalDeliveryAddress?.helptext as string,
                        }}
                      />
                    </div>
                  )}
                </div>
                {transFormFields?.SpecialPickupInformation && (
                  <div
                    className={fieldWrapper({
                      className: textareaWrapper(),
                    })}
                  >
                    <TextAreaField
                      {...transFormFields?.SpecialPickupInformation}
                      setFieldValue={setValues}
                      className="[&>*>span]:!hidden"
                    />
                    <TextHelper
                      tag={'p'}
                      field={{
                        value: transFormFields?.SpecialPickupInformation?.helptext as string,
                      }}
                    />
                  </div>
                )}

                <div className={inlineFields()}>
                  {saveLoading ? (
                    <div className={loaderWrapper()}>
                      <Loader />
                      {getDictionaryValue('Saving')}
                    </div>
                  ) : (
                    <>
                      {fields.data?.data?.submitButtonText?.value && (
                        <button aria-label="submit" className={submitBtn()} type="submit">
                          <TextHelper field={fields.data?.data?.submitButtonText} />
                        </button>
                      )}
                      {fields.data?.data?.cancelButtonText?.value && (
                        <button
                          aria-label="reset"
                          type="reset"
                          className={cancelBtn()}
                          onClick={() => {
                            resetForm;
                            setFieldValues(initialValues);
                            setcomponentContextData({
                              ...componentContextData,
                              collapse: null,
                            });
                          }}
                        >
                          <TextHelper field={fields.data?.data?.cancelButtonText} />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </FormikForm>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<MyAccountSpecialInstructionsProps>(
  MyAccountSpecialInstructions
);
