const sendStoreUpdate = async (storenumber: string) => {
  try {
    const response = await fetch(`/api/homestore/homestorealert?storenumber=${storenumber}`);
    if (!response.ok && response.status !== 404) {
      throw new Error('Error. The API response was not ok.');
    }
  } catch (error) {
    console.info(`Error. An error occured in the API response. ${error}`);
  } finally {
  }
};

export default sendStoreUpdate;
