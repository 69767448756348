import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import { JsonLink } from 'lib/types/header';
import { newNavigationTailwindVariant } from 'tailwindVariants/components/NewnavigationTailwindVariant';
import IconHelper from '../commonComponents/IconHelper';
import TextHelper from '../commonComponents/TextHelper';
import LinkHelper from '../commonComponents/LinkHelper';

const BackText = ({
  backText,
  toggleItem,
  setIsHamburger,
  link,
  shopAll,
}: {
  backText: Field<string>;
  toggleItem?: () => void;
  setIsHamburger?: () => void;
  link?: JsonLink;
  shopAll?: JsonLink;
}) => {
  const { iconWrapper, fillBlack, iconXSmall, bodyText, shopAllLink, flexGrid, mobSubWrapper } =
    newNavigationTailwindVariant();
  return (
    <div className={clsx(flexGrid(), mobSubWrapper(), 'relative')}>
      <div
        className={clsx(
          'gap-mob-global-spacing-spacing-1',
          shopAll?.jsonValue?.value?.text ? 'mr-auto flex' : 'flex'
        )}
        onClick={toggleItem}
      >
        <IconHelper
          icon={'chevron-left'}
          className={clsx(iconWrapper(), fillBlack(), iconXSmall())}
        />
        <TextHelper tag="button" field={backText} className="!font-normal" />
      </div>
      {link?.jsonValue?.value?.text && (
        <span
          className={clsx(
            bodyText(),
            shopAll?.jsonValue?.value?.href
              ? 'ml-auto mr-auto'
              : 'absolute left-1/2 transform -translate-x-1/2 text-center'
          )}
        >
          {link?.jsonValue?.value?.text}
        </span>
      )}
      {shopAll?.jsonValue && (
        <LinkHelper
          className={clsx(shopAllLink(), 'ml-auto')}
          field={shopAll?.jsonValue}
          onClick={() => {
            toggleItem && toggleItem(), setIsHamburger && setIsHamburger();
          }}
        />
      )}
    </div>
  );
};
export default BackText;
