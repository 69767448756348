import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { Me, RequiredDeep } from 'ordercloud-javascript-sdk';
import { createOcAsyncThunk, OcThrottle } from '../ocReduxHelpers';
import { MeUserWithXp } from '../xp';
import { getSerializableObject } from '../utils';
import { defaultBuyer } from 'src/helpers/Constants';

interface ocUserState {
  user?: MeUserWithXp;
  loading: boolean;
  error?: SerializedError;
  travelStartDate?: string;
  travelEndDate?: string;
}

const initialState: ocUserState = {
  loading: false,
};

const userThrottle: OcThrottle = {
  location: 'ocUser',
  property: 'loading',
};

export const getUser = createOcAsyncThunk<RequiredDeep<MeUserWithXp>, undefined>(
  'ocUser/get',
  async () => {
    return Me.Get();
  },
  userThrottle
);

export const updateUser = createOcAsyncThunk<
  RequiredDeep<MeUserWithXp | undefined>,
  Partial<MeUserWithXp>
>('ocUser/update', async (data) => {
  //If you call Patch without ID, it will create a new ID for User.
  if (data?.ID && data?.ID?.toLowerCase() !== defaultBuyer?.ID) {
    return Me.Patch(data);
  }
  return undefined;
});

const ocUserSlice = createSlice({
  name: 'ocUser',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.user = undefined;
    },
    updateTravelStartDate: (state, action: PayloadAction<string>) => {
      state.travelStartDate = action.payload;
    },
    updateTravelEndDate: (state, action) => {
      state.travelEndDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loading = false;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.user = undefined;
      state.error = getSerializableObject(action.error);
      state.loading = false;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.error = getSerializableObject(action.error);
      state.loading = false;
    });
  },
});

export const { clearUser, updateTravelEndDate, updateTravelStartDate } = ocUserSlice.actions;

export default ocUserSlice.reducer;
