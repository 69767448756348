//global
import React, { useState } from 'react';
import clsx from 'clsx';
//local
import { newNavigationTailwindVariant } from 'tailwindVariants/components/NewnavigationTailwindVariant';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import {
  Link as JssLink,
  NextImage,
  Placeholder,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-nextjs';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
//lib
import { DPNTargetItems } from 'lib/types/header';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
//types
type DesktopNavProps = {
  item: DPNTargetItems;
  isActive: string;
  modelref?: React.LegacyRef<HTMLUListElement | undefined> | undefined;
  handleisActive: (val: string) => void;
  parentIndex?: number; //for nested navigation
  rendering: ComponentRendering;
};
export default function DesktopNav({
  item,
  isActive,
  modelref,
  handleisActive,
  parentIndex,
  rendering,
}: DesktopNavProps): JSX.Element {
  const {
    flexGrid,
    navigationOpen,
    navigationAfter,
    liWrapper,
    subMenuWrapper,
    h4,
    navigationLink,
    bodyRegular,
    deskSubNavWrapper,
    deskSubNavHeading,
    normalFont,
    flexCol,
    textBlack,
    shopAllLink,
    threeColLayout,
    levelOneCarrot,
    colLayoutOne,
    colLayoutOneParentItem,
    colLayoutTwo,
    colLayoutTopWrapper,
    colLayoutThree,
    colLayoutTwoCols,
    colLinkWrapper,
    colLinkItems,
    colLinkTitle,
    flexRow,
    shopNavigation,
    colLayoutTwoChilds,
    colLayoutOneCategories,
    brandsWrapper,
    brandsMainWrapper,
    brandsTabs,
    brandsTabsItem,
    brandNavigation,
    brandIconsWrapper,
    brandIconLinkText,
    brandIconLink,
    brandsTitle,
  } = newNavigationTailwindVariant();
  const hasChildren = item?.childrens?.results && item?.childrens?.results?.length > 0;
  const isEE = useExperienceEditor();

  const [activeShopCategory, setActiveShopCategory] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<string | undefined>('All');

  const getFilteredItems = () => {
    if (activeTab === 'All') {
      return item?.childrens?.results.flatMap((brandItems) => brandItems?.childrens?.results || []);
    }
    const [start, end] = activeTab?.split('-') || []; // Extract start and end letters
    return item?.childrens?.results
      .flatMap((brandItems) => brandItems?.childrens?.results || [])
      .filter(
        (brandItem) =>
          brandItem?.name &&
          brandItem.name[0].toUpperCase() >= start &&
          brandItem.name[0].toUpperCase() <= end
      );
  };

  const filteredItems = getFilteredItems();

  //handling keydown event for accessibility
  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>, itemName?: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      isActive !== itemName ? handleisActive(itemName as string) : handleisActive(''),
        event.preventDefault();
    } else if (event.key === 'Escape') {
      handleisActive('');
    }
  };

  return (
    <li
      key={item?.name}
      className={clsx(
        liWrapper(),
        !item?.isBrand?.jsonValue?.value && 'relative',
        isActive === item?.name && 'bg-color-brand-primary-1-dark'
      )}
    >
      {item?.childrens?.results?.length > 0 ? (
        item?.link?.jsonValue && (
          <div className="flex items-center">
            <JssLink
              tabIndex={0}
              editable={false}
              role="button"
              field={item?.link?.jsonValue}
              className={clsx(
                'cursor-pointer',
                navigationLink(),
                `${isActive === item?.name ? clsx(navigationAfter()) : ''}`
              )}
              onClick={(e) => {
                hasChildren ? handleisActive(item?.name as string) : handleisActive(''),
                  e.preventDefault();
              }}
              onKeyDown={(e) => {
                handleKeyDown(e, item?.name);
              }}
            />
            <IconHelper
              icon={'Caret'}
              className={levelOneCarrot({ className: 'ml-1' })}
              onClick={() => {
                hasChildren ? handleisActive(item?.name as string) : handleisActive('');
              }}
            />
          </div>
        )
      ) : item?.link?.jsonValue?.value?.href && item?.link?.jsonValue?.value?.href?.length > 0 ? (
        <LinkHelper
          onClick={() => {
            handleisActive('');
          }}
          editable={false}
          field={item?.link?.jsonValue}
          className={clsx(
            navigationLink(),
            `${isActive === item?.name ? clsx(navigationAfter(), textBlack()) : ''}`
          )}
        />
      ) : null}
      {isActive == item?.name && (
        <IconHelper
          icon="navigationOpen"
          className={`absolute bottom-0 left-0 w-full flex justify-center ${
            item?.isBrand?.jsonValue?.value && '!left-[445px] !w-fit !block'
          }`}
        />
      )}
      {isActive !== item?.name ? null : (
        <ul
          data-component-section={'deskutility'}
          ref={modelref as React.LegacyRef<HTMLUListElement> | undefined}
          className={clsx(
            parentIndex != null && parentIndex < 3 && [shopNavigation(), 'firstthree'],
            item?.isBrand?.jsonValue?.value && brandNavigation(),
            parentIndex != null && parentIndex > 3 && [navigationOpen(), 'lastthree']
          )}
        >
          <li>
            <div className={subMenuWrapper()}>
              {/**Shops menu children i.e. Dog, Cat, Fish etc. */}
              {item?.isShop?.jsonValue?.value ? (
                <div className={threeColLayout()}>
                  <div className={colLayoutOne()}>
                    {item?.childrens?.results?.map((gchild, index) => {
                      return (
                        <div
                          key={index}
                          className={clsx(
                            colLayoutOneCategories(),
                            activeShopCategory === index && 'bg-color-background-light-mid'
                          )}
                          onClick={() => {
                            setActiveShopCategory(index);
                          }}
                        >
                          <p
                            className={clsx(
                              colLayoutOneParentItem({ className: 'colLayoutOneParentItem' })
                            )}
                          >
                            {gchild?.name}
                          </p>
                          <IconHelper
                            icon={'breadcrumb-down'}
                            className="[&>svg>path]:fill-color-text-dark rotate-[270deg]"
                          />
                        </div>
                      );
                    })}
                  </div>

                  {/**Shops inner menu children i.e Food, Treats, Fashion etc.  */}
                  <div className={colLayoutTwo()}>
                    <div className={colLayoutTopWrapper()}>
                      {item?.childrens?.results?.[activeShopCategory]?.childrens?.results?.map(
                        (gchildinner, innerindex) => {
                          return (
                            gchildinner?.childrens?.results &&
                            gchildinner?.childrens?.results?.length > 0 && (
                              <div className={colLayoutTwoCols()} key={`${innerindex}`}>
                                {gchildinner?.childrens?.results?.map((colChilds, colIndex) => (
                                  <div className={colLayoutTwoChilds()} key={colIndex}>
                                    <div
                                      className={colLinkTitle({
                                        className:
                                          'colLinkWrapper flex items-center gap-global-spacing-spacing-05 py-2',
                                      })}
                                    >
                                      {colChilds?.link?.jsonValue?.value?.text}
                                      <LinkHelper
                                        className={shopAllLink({ className: 'px-2' })}
                                        field={colChilds?.shopAll?.jsonValue}
                                        onClick={() => {
                                          handleisActive('');
                                        }}
                                      />
                                    </div>

                                    {colChilds?.childrens?.results &&
                                      colChilds?.childrens?.results?.length > 0 && (
                                        <div className={colLinkWrapper()}>
                                          {colChilds?.childrens?.results?.map(
                                            (linkChild, linkIndex) => (
                                              <LinkHelper
                                                key={linkIndex}
                                                className={colLinkItems({
                                                  className: 'colLinkItems py-1',
                                                })}
                                                field={linkChild?.link?.jsonValue}
                                                onClick={() => handleisActive('')}
                                              />
                                            )
                                          )}
                                        </div>
                                      )}
                                  </div>
                                ))}
                              </div>
                            )
                          );
                        }
                      )}
                    </div>
                    <div className="layout-two-bottom-wrapper">
                      <Placeholder
                        name={`popular-categories-shop-${activeShopCategory}`}
                        rendering={rendering}
                      />
                    </div>
                  </div>

                  <div
                    data-placeholder-id={`primary-nav-desktop-${activeShopCategory}`}
                    className={
                      isEE ? 'flex flex-col self-start w-full' : 'flex flex-col self-start'
                    }
                  >
                    <Placeholder
                      name={`primary-nav-shop-desktop-${activeShopCategory}`}
                      rendering={rendering}
                      params={{ className: colLayoutThree() }}
                    />
                  </div>
                </div>
              ) : item?.isBrand?.jsonValue?.value ? (
                <div className={clsx(brandsWrapper())}>
                  <div
                    className={clsx(
                      'flex-col px-desk-global-spacing-spacing-5 py-desk-global-spacing-spacing-4'
                    )}
                  >
                    <p className={brandsTitle()}>{item?.name}</p>
                    <div className={brandsMainWrapper()}>
                      <ul className={brandsTabs()}>
                        <li
                          className={clsx(
                            brandsTabsItem(),
                            activeTab === 'All' &&
                              'bg-color-brand-primary-1-base text-color-text-white'
                          )}
                          key="all"
                          onClick={() => setActiveTab('All')}
                        >
                          All
                        </li>
                        {item?.childrens?.results?.map((brandItems, index) => (
                          <li
                            className={clsx(
                              brandsTabsItem(),
                              activeTab === brandItems?.name &&
                                'bg-color-brand-primary-1-base text-color-text-white'
                            )}
                            key={index}
                            onClick={() => setActiveTab(brandItems?.name)}
                          >
                            {brandItems?.name}
                          </li>
                        ))}
                      </ul>
                      <div className={brandIconsWrapper()}>
                        {filteredItems?.map((brandItem, index) => (
                          <div key={index}>
                            <LinkHelper
                              field={brandItem?.link?.jsonValue}
                              className={brandIconLink()}
                            >
                              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                              <NextImage field={(brandItem as any)?.brandImage?.jsonValue?.value} />
                              <p className={brandIconLinkText()}>{brandItem?.name}</p>
                            </LinkHelper>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={flexGrid()}>
                    <div className={flexRow({ className: 'desk-rows' })}>
                      <div className="top flex flex-col">
                        <div className="top flex flex-row">
                          {item?.childrens &&
                            item?.childrens?.results?.flatMap((child, ind) => {
                              const gchildLength = item?.childrens?.results?.length;
                              return (
                                <div
                                  className={clsx(flexCol({ className: 'desk-cols' }))}
                                  key={child?.name}
                                >
                                  {child?.childrens
                                    ? child?.childrens?.results?.map((gchild, index) => {
                                        const hasChildren =
                                          gchild?.childrens?.results &&
                                          gchild?.childrens?.results?.length > 0;
                                        return (
                                          <div
                                            key={index}
                                            className={clsx(
                                              { '!pb-0': index === 0 },
                                              deskSubNavHeading({
                                                className: !hasChildren && index !== 0 && '!py-0',
                                              })
                                            )}
                                          >
                                            {hasChildren
                                              ? !child?.hideTitle?.jsonValue?.value &&
                                                ind !== gchildLength && (
                                                  <>
                                                    {gchild?.icon === null && (
                                                      <div
                                                        className={h4({
                                                          className:
                                                            'deskSubNavHeading flex items-center gap-global-spacing-spacing-05 py-2',
                                                        })}
                                                      >
                                                        {gchild?.link?.jsonValue?.value?.text}
                                                        <LinkHelper
                                                          className={shopAllLink({
                                                            className: 'px-2',
                                                          })}
                                                          field={gchild?.shopAll?.jsonValue}
                                                          onClick={() => {
                                                            handleisActive('');
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                  </>
                                                )
                                              : gchild?.link?.jsonValue?.value?.href && (
                                                  <LinkHelper
                                                    className={h4({
                                                      className:
                                                        'mb-desk-global-spacing-spacing-2 deskSubNavHeading',
                                                    })}
                                                    field={gchild?.link?.jsonValue}
                                                    onClick={() => {
                                                      handleisActive('');
                                                    }}
                                                  />
                                                )}
                                            {hasChildren && (
                                              <ul className={deskSubNavWrapper()}>
                                                {gchild?.childrens?.results?.map((child, index) => {
                                                  const href = child?.link?.jsonValue?.value?.href;
                                                  // Ensure all required properties exist before rendering
                                                  if (child?.link && href && href?.length > 0) {
                                                    return (
                                                      <li key={index} className={normalFont()}>
                                                        <LinkHelper
                                                          onClick={() => handleisActive('')}
                                                          editable={false}
                                                          field={child.link.jsonValue}
                                                          className={clsx(bodyRegular())}
                                                        />
                                                      </li>
                                                    );
                                                  }
                                                  return null; // Skip rendering if link or href is invalid
                                                })}
                                              </ul>
                                            )}
                                          </div>
                                        );
                                      })
                                    : ''}
                                </div>
                              );
                            })}
                        </div>
                        <div className="popular-categories-wrapper layout-two-bottom-wrapper">
                          <Placeholder
                            name={`popular-categories-${parentIndex}`}
                            rendering={rendering}
                          />
                        </div>
                      </div>
                      <div
                        data-placeholder-id={`primary-nav-desktop-${parentIndex}`}
                        className={clsx(
                          isEE ? 'flex flex-col self-start w-full' : 'flex flex-col self-start'
                        )}
                      >
                        <Placeholder
                          name={`primary-nav-desktop-${parentIndex}`}
                          rendering={rendering}
                          params={{ className: colLayoutThree() }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </li>
        </ul>
      )}
    </li>
  );
}
