import { Field, LinkField, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
//import { useRouter } from 'next/router';
import { Order } from 'ordercloud-javascript-sdk';
import { useEffect, useState, useContext } from 'react';
import ComponentContext from 'lib/context/ComponentContext';
import { useTheme } from 'lib/context/ThemeContext';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import {
  DiscreteLineItem,
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  pointPerDollar,
} from 'src/helpers/Constants';
import useOcCart from 'src/hooks/useOcCart';
import { orderConfirmationVariant } from 'tailwindVariants/components/orderConfirmationTailwindVariant';
import { orderConfirmationSummaryVariant } from 'tailwindVariants/components/orderConfirmationSummaryTailwindVariant';
import useOcCurrentOrder from 'src/hooks/useOcCurrentOrder';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { trackOrderConfirmEvent } from '@sitecore-search/react';
import useDictionary from 'src/hooks/useDictionary';
import Loader from 'components/Loader/Loader';
import { DateTime } from 'luxon';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { getUser } from 'src/redux/ocUser';
import { CommerceEntityPayload } from '@sitecore-search/data/dist/esm/events/models/payloads';

export type OrderConfirmationProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      createAccountText?: Field<string>;
      pickupOrderInfo?: Field<string>;
      deliveryOrderInfo?: Field<string>;
      rewardsText?: Field<string>;
      title?: Field<string>;
      loginRewardsText?: Field<string>;
      loginRewardsLink?: LinkField;
      autoshipText?: Field<string>;
    };
  };

const OrderConfirmation = ({ fields, params }: OrderConfirmationProps): JSX.Element => {
  const {
    calloutBase,
    calloutTextWrapper,
    title,
    calloutTitle,
    calloutText,
    link,
    formContainer,
    shortDescription,
  } = orderConfirmationVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const { loaderWrapper } = orderConfirmationSummaryVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const [isUserUpdateAfterAutoshipOrder, setIsUserUpdateAfterAutoshipOrder] = useState(false);
  const { componentContextData } = useContext(ComponentContext);
  const [result, setResult] = useState<Order>({});
  const [userName, setUserName] = useState<string | null>(null);
  const { themeNameUpper } = useTheme();
  const [rewardPoints, setRewardPoints] = useState<number>(0);
  const { getProductLineItemsTotal } = useOcCart();
  const [name, setName] = useState<string>('');
  const [delivery, setDelivery] = useState<boolean | undefined>(undefined);
  const [isDataPushed, setIsDataPushed] = useState(false);
  const { getDictionaryValue } = useDictionary();
  const orders = useOcCurrentOrder()?.recentOrders;
  const receivedFirstTimeAutoshipDiscount = useOcSelector(
    (state) => state.ocUser.user?.xp?.ReceivedFirstTimeAutoshipDiscount
  );
  const dispatch = useOcDispatch();

  const getUserData = async () => {
    await dispatch(getUser());
    setIsUserUpdateAfterAutoshipOrder(true);
  };
  useEffect(() => {
    if (componentContextData?.lineItem) {
      const totalValue = getProductLineItemsTotal(componentContextData?.lineItem);
      setRewardPoints(Math.round((totalValue ?? 1) * pointPerDollar));
    }

    // Fetching name from Delivery Info or Pickup Info
    const order = orders?.[0]?.order;
    if (
      order?.xp?.AutoshipFrequency &&
      !isUserUpdateAfterAutoshipOrder &&
      !receivedFirstTimeAutoshipDiscount
    ) {
      getUserData();
    }
    if (order) {
      const fulfillmentType = order?.xp?.Fulfillment;
      const pickupInfo = order?.xp?.PickupInfo;
      const shippingAddress = componentContextData?.lineItem?.[0]?.ShippingAddress;

      if (fulfillmentType === FulfillmentType?.BOPIS) {
        if (pickupInfo) {
          setName(pickupInfo?.FirstName);
        }
      } else if (shippingAddress) {
        setName(shippingAddress?.FirstName ?? '');
      }
    }
  }, [componentContextData?.lineItem, orders?.[0]?.order]);

  const store = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const [loadingMessage, setLoadingMessage] = useState<boolean>(true);
  useEffect(() => {
    if (orders?.[0]?.order) {
      setResult(orders?.[0]?.order);
    }
    if (orders?.[0]?.order?.xp?.OrderId) {
      if (fields.pickupOrderInfo?.value) {
        fields.pickupOrderInfo.value = fields.pickupOrderInfo?.value.replace(
          '{orderid}',
          orders?.[0]?.order?.xp?.OrderId ?? ''
        );
      }
      if (fields.deliveryOrderInfo?.value) {
        fields.deliveryOrderInfo.value = fields.deliveryOrderInfo?.value
          .replace('{orderid}', orders?.[0]?.order?.xp?.OrderId ?? '')
          .replace(
            '[delivery date]',
            DateTime.fromFormat(
              orders?.[0]?.order?.xp?.ExpectedDeliveryDate || '',
              'M/d/yyyy h:mm:ss a'
            ).toFormat('MMMM d, yyyy')
          );
      }
    }

    if (
      orders?.[0]?.order?.xp?.Fulfillment === FulfillmentType?.BOPIS ||
      orders?.[0]?.order?.xp?.Fulfillment === FulfillmentType?.DFS
    ) {
      // need to imply useState.
      setDelivery(orders?.[0]?.order?.xp?.Fulfillment == FulfillmentType?.DFS);
      setLoadingMessage(false);
    }
  }, [fields, orders?.[0]?.order, orders?.[0]?.order?.xp?.Fulfillment]);

  useEffect(() => {
    // Check if both name and fields.Title?.value exist
    if (name && typeof fields?.title?.value === 'string') {
      const trimmedValue = fields.title?.value.trim();
      fields.title.value = trimmedValue.replace('{name}', name);
      setUserName(trimmedValue.replace('{name}', name) as string);
    }
  }, [result?.FromUser, fields]);

  //sending GTM data  -----
  const { currentPage, pageItem } = getGTMSessionStorage();
  useEffect(() => {
    const data = orders?.[0]?.lineItems?.filter(
      (product) => product?.ProductID !== DiscreteLineItem.TIP
    );
    const productData = data?.map((product) => {
      const products = {
        ...product?.Product,
        quantity: product?.Quantity,
        BasePrice: product?.UnitPrice,
        listPrice: product?.UnitPrice,
      };
      return products;
    });

    const deliveryTip = orders?.[0]?.lineItems?.filter(
      (product) => product?.ProductID === DiscreteLineItem.TIP
    )?.[0]?.LineTotal;
    const isDFS = orders?.[0]?.order?.xp?.Fulfillment === FulfillmentType?.DFS;
    const order = orders?.[0]?.order;
    const totalAmount =
      order?.Total - order?.ShippingCost - Number(deliveryTip) - Number(order?.TaxCost);
    orders &&
      orders?.length > 0 &&
      !isDataPushed &&
      (setIsDataPushed(true),
      sendProductsPromotion({
        eventName: GTM_EVENT?.purchase,
        data: productData as ProductSearchResultModelWithVariants[],
        currentPage: currentPage,
        pageItem: pageItem,
        storeId: order?.ToCompanyID,
        currency: true,
        paymentMethod: order?.xp?.CardType,
        purchaseType:
          isDFS || orders?.[0]?.order?.xp?.Fulfillment === FulfillmentType?.BOPIS
            ? GTMLabels?.firstTime
            : GTMLabels?.autoShip,
        deliveryTip: Number(deliveryTip)?.toFixed(2),
        shipping: order?.ShippingCost,
        transactionId: order?.xp?.OrderId,
        retailType: !isDFS
          ? GTMLabels?.curbSide
          : isDFS && order?.xp?.IsSubscription
          ? GTMLabels?.autoShip
          : GTMLabels?.delivery,
        tax: Number(order?.TaxCost)?.toFixed(2),
        coupon: order?.xp?.CouponCode,
        purchaseChannel: order?.xp?.Fulfillment,
        totalCount: totalAmount,
      }));
  }, [orders]);
  //----------------------------

  useEffect(() => {
    //sending Order Confirmation data to Search Events  -----

    const orderConfirmItems: CommerceEntityPayload[] = [];
    const orderData = orders?.[0]?.lineItems?.filter(
      (product) => product?.ProductID !== DiscreteLineItem.TIP
    );
    const orderedProductIds = orderData?.map((product) => {
      return {
        id: product?.Product?.ID || '',
        ListPrice: product?.xp?.ListPrice || 0,
        Quantity: product?.Quantity || 0,
        LineTotal: product?.LineTotal || 0,
      };
    });
    const order = orders?.[0]?.order;

    if (orderedProductIds && orderedProductIds?.length > 0) {
      orderedProductIds.forEach((op) => {
        const price = op?.ListPrice / op?.Quantity;
        const finalPrice = op?.LineTotal / op?.Quantity;
        const opItem = {
          entityType: 'product',
          id: op?.id,
          price: price,
          finalPrice: finalPrice,
          quantity: op?.Quantity,
        };
        orderConfirmItems.push(opItem);
      });
    }

    trackOrderConfirmEvent('product', {
      items: orderConfirmItems,
      orderSubtotal: order?.Subtotal,
      orderId: order?.xp.OrderId,
      orderTotal: order?.Total,
      actionSubtype: 'conversion',
    });
  }, [orders]);

  const freq = store.autoshipSettings?.subscriptionFrequency.find(
    (x) => x.days === orders?.[0]?.order?.xp?.AutoshipFrequency
  );

  const freqText =
    freq?.label ??
    `${getDictionaryValue('EveryText')} ${Math.floor(
      orders?.[0]?.order?.xp?.AutoshipFrequency / 7
    )} ${getDictionaryValue('WeeksText')}`;

  return (
    <div className={'orderconfirmation ' + params?.Styles}>
      <div className="w-full">
        <div className={formContainer()}>
          {userName ? (
            <>
              <Text field={{ value: userName }} className={title()} tag="p" />{' '}
            </>
          ) : null}

          {loadingMessage && (
            <div className={loaderWrapper()}>
              <Loader />
            </div>
          )}
          {!loadingMessage && !delivery && fields?.pickupOrderInfo?.value && (
            <>
              <RichText field={fields?.pickupOrderInfo} className={shortDescription()} />
            </>
          )}
          {!loadingMessage && delivery && fields?.deliveryOrderInfo?.value && (
            <>
              <RichText field={fields?.deliveryOrderInfo} className={shortDescription()} />
            </>
          )}
          {!loadingMessage &&
          delivery &&
          fields?.autoshipText?.value &&
          orders?.[0]?.order?.xp?.AutoshipFrequency ? (
            <>
              <RichTextHelper
                field={fields?.autoshipText}
                updatedField={{
                  value: fields?.autoshipText?.value?.replace(
                    '{frequency}',
                    `<strong>${freqText}</strong>`
                  ),
                }}
                className={shortDescription()}
              />
            </>
          ) : (
            <></>
          )}
          {/* <CalloutBlock /> */}
          <div className={calloutBase()}>
            {/* show title only when guest user */}
            {fields?.createAccountText?.value && isAnonymous && (
              <Text field={fields?.createAccountText} className={calloutTitle()} tag="p" />
            )}
            <div
              className={calloutTextWrapper({
                className: !isAnonymous ? '!bg-transparent !p-[0px]' : '',
              })}
            >
              {themeNameUpper == 'PSP' ? (
                <IconHelper icon={'Star'}></IconHelper>
              ) : (
                <IconHelper icon={'wnw-rewards'}></IconHelper>
              )}
              {isAnonymous ? (
                <RichTextHelper
                  className={calloutText()}
                  field={fields?.rewardsText}
                  {...(fields?.rewardsText?.value?.includes('{rewardpoint}') && {
                    updatedField: {
                      value: fields?.rewardsText?.value?.replace(
                        '{rewardpoint}',
                        rewardPoints?.toString() || ''
                      ),
                    },
                  })}
                />
              ) : (
                <>
                  <RichTextHelper
                    className={calloutText()}
                    field={fields?.loginRewardsText}
                    {...(fields?.loginRewardsText?.value?.includes('{rewardpoint}') && {
                      updatedField: {
                        value: fields?.loginRewardsText?.value?.replace(
                          '{rewardpoint}',
                          rewardPoints?.toString() || ''
                        ),
                      },
                    })}
                  />
                  <LinkHelper field={fields?.loginRewardsLink} className={link()} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderConfirmation;
