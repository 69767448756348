import React, { useEffect, useState } from 'react';
import { ComponentProps } from 'lib/component-props';
import { Me, ListPage } from 'ordercloud-javascript-sdk';
import { useOcSelector } from 'src/redux/ocStore';
import { BuyerProductWithXp } from 'src/redux/xp';
import useOcCart from 'src/hooks/useOcCart';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { LineItemWithXp } from 'src/redux/xp';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';

export type AddToCartProps = ComponentProps;

const AddToCart: React.FC<AddToCartProps> = ({}) => {
  const { getProductLineItems } = useOcCart();
  const productlineitems: LineItemWithXp[] = getProductLineItems();
  const [result, setResult] = useState<ListPage<BuyerProductWithXp>>();
  const [searchInput, setSearchInput] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [progress, setProgress] = useState('');
  const { headerContextData, setHeaderContextData } = useHeaderContext();

  const { addToCart } = useOcCart();
  const selectedStore = useOcSelector((state) => state?.storeReducer?.selectedStore);

  useEffect(() => {
    if (searchInput.length > 3) {
      const initialize = async () => {
        const products = await Me.ListProducts<BuyerProductWithXp>({
          search: searchInput,
          filters: { IsParent: false, 'xp.WE': false },
          sellerID: selectedStore.storeId as string,
          searchOn: ['ID', 'Name'],
        });
        setResult(products);
      };
      initialize();
    }
  }, [searchInput, selectedStore.storeId]);

  const handleProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target?.value.length > 3) setSearchInput(e.target?.value);
  };

  const handleQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const q = parseInt(e.target?.value);
    q > 0 && setQuantity(q);
  };

  const handleAddToCart = async (product: BuyerProductWithXp) => {
    setHeaderContextData({
      ...headerContextData,
      openMiniCart: true,
      showMiniCartLoader: true,
    });

    if (!progress && product.ID) {
      setProgress(product.ID);

      await addToCart({ productId: product.ID, quantity });

      setQuantity(1);
    }
    setProgress('');
  };

  //if (fields === undefined || fields === null) return <></>;
  const count: number = productlineitems?.length ?? 0;
  return (
    <div className="row h-64">
      <div className="column float-left w-8/12">
        <div className="addtocart grid place-items-center mt-10">
          {count > 0 && (
            <>
              <LinkHelper
                className="py-2 px-4 border-2 mb-2 hover:bg-color-brand-primary-1-base hover:no-underline hover:text-white"
                field={{
                  value: {
                    href: '/checkout',
                    text: 'Proceed to Checkout',
                  },
                }}
              />
              <LinkHelper
                className="py-2 px-4 border-2 mb-2 hover:bg-color-brand-primary-1-base hover:no-underline hover:text-white"
                field={{
                  value: {
                    href: '#',
                    text: 'Place Order',
                  },
                }}
              />
            </>
          )}
          <label className="text-emerald-600">Current Cart lineitems: {count}</label>
          <label className="text-xl">Search Products:</label>
          <input
            aria-label="add to cart quantity"
            className="text-lg border-solid border-[3px] px-[10px]"
            type="search"
            onChange={handleProduct}
          />
          <label>Quantity</label>
          <input
            aria-label="quantity"
            className="text-lg border-solid border-[2px] pl-[10px]"
            type="number"
            name="qty"
            min="1"
            max="100"
            value={quantity}
            onChange={handleQuantity}
          />

          {!result || result?.Items?.length === 0 ? (
            <div className="mt-6 w-52">No Product found</div>
          ) : (
            <div className="mt-6 w-62">
              {result?.Items?.map((p, i) => {
                const price: number =
                  p.PriceSchedule?.PriceBreaks?.[0]?.SalePrice ??
                  p.PriceSchedule?.PriceBreaks?.[0]?.Price ??
                  0;
                return (
                  <div key={i} className="flex mb-5">
                    <a
                      aria-label={p.ID}
                      className="text-lg"
                      target="_blank"
                      href={`/categories/dog/food/dry-food/redford-naturals-puppy-salmon--brown-rice-recipe-dog-food/${p?.ParentID}?sku=${p?.xp?.UPC}`}
                    >
                      {p.ID}
                    </a>
                    <span className="text-slate-500 ml-4">{`[$${price.toFixed(2)}]`}</span>
                    <a
                      aria-label="add to cart"
                      className="cursor-pointer ml-5 w-30 h-8 bg-color-brand-primary-1-base p-2 text-center rounded text-white"
                      onClick={() => handleAddToCart(p)}
                    >
                      {progress == p.ID ? 'adding...' : 'ADD TO CART'}
                    </a>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="column float-left w-4/12">
        {productlineitems?.length &&
          productlineitems.map((line, i) => {
            const image = getFirstProductImage(line.Product?.xp?.Images || []);
            return (
              <>
                <div key={i} className={'lines ' + ''}>
                  <div className="float-left">
                    <a aria-label="image" href="#">
                      <NextImage
                        field={{
                          value: {
                            src: image?.Url,
                          },
                        }}
                        alt={image?.Name}
                        width="100"
                        height="100"
                      />
                    </a>
                  </div>
                  <div className="float-left">
                    <div>
                      <div>{line?.Product?.Name}</div>
                      <div>Quantity: {line?.Quantity}</div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                        <div></div>
                        <div>LineTotal: ${line?.LineTotal} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};
export const Default = (props: AddToCartProps): JSX.Element => {
  return <AddToCart {...props} />;
};
