import { tv } from 'tailwind-variants';

export const navigationTailwindVariant = tv(
  {
    slots: {
      // *common
      justifyBetween: ['justify-between '],
      justifyCenter: [' justify-center '],
      justifyNormal: ['!justify-normal'],
      itemsEnd: ['!items-end'],
      itemsBaseLine: ['!items-baseline'],
      itemStrech: ['items-strech'],
      itemStart: ['!items-start'],
      flexEnd: ['justify-end'],
      border0: ['!border-0'],
      bodyRegular: [''],
      relative: [''],
      flexColWmax: ['flex-col w-max'],
      wFit: ['w-fit'],
      wFull: ['w-full'],
      w30: ['max-w-[30px] max-h-[30px]'],
      textBlack: ['!text-color-text-black'],
      gap2: ['gap-2'],
      hover: ['hover:text-color-brand-primary-1-base'],
      cursorNone: ['hover:cursor-default'],
      borderBottom: ['border-b-[1px] border-color-scale-5-mid'],
      // *base
      base: ['font-primary'],
      deskBase: ['lg:overflow-visible relative font-primary'],
      // *mobile primary nav
      mobRightWrapper: ['lg:py-3 lg:justify-self-end'],
      mobLeftWrapper: ['lg:hidden'],
      mobDivWrapper: [''],
      subMenuWrapper: [''],
      mobileWrapper:
        'lg:hidden leading-5 font-body-large-bold text-body-xLarge-regular overflow-y-auto mt-[0px] bg-color-background-brand-white   z-[11] capitalize text-xxs  overflow-hidden w-full transition-all    duration-700 ease-in-out ',
      mobileNavOpen: [
        'absolute lg:hidden backface-hidden translate-x-full overflow-y-scroll overscroll-contain transition-transform duration-300 ease-in w-full  left-0 top-0 right-0 bg-color-background-white',
      ],
      mobCloseFont: ['text-color-text-brand-1 flex items-center'],
      pr10: ['pr-[10px]'],
      h250: ['h-[250px]'],
      mobIcon: ['text-color-scale-1-white text-2xl'],
      miniCartIcon: ['minicart-icon'],
      closeIcon: ['cursor-pointer [&>svg>*>*]:fill-color-scale-8-dark'],
      fillBlack: ['[&>svg>*>*]:fill-color-scale-8-dark [&>svg>*]:fill-color-scale-8-dark'],
      fillBase: [
        '[&>svg>*>*]:fill-color-brand-primary-1-base [&>svg>*]:fill-color-brand-primary-1-base',
      ],
      iconFit: ['[&>svg]:h-fit'],
      iconSmall: ['w-6 flex'],
      iconXSmall: ['w-4 flex'],
      // * dsktop primary nav
      desktopWrapper:
        'flex py-mob-global-spacing-spacing-3 lg:py-0 leading-5 bg-color-brand-primary-1-base justify-between lg:justify-center flex-wrap items-center px-2 lg:grid lg:grid-cols-6 flex-nowrap mobile-headerlogo-wrapper',
      deskNavItem: ['hidden lg:flex lg:col-span-4 lg:justify-center lg:col-start-2 '],
      deskSubNavWrapper: ['flex flex-col gap-desk-margin-tight-bottom'],
      deskSubNavHeading: [
        'w-full flex flex-col gap-desk-margin-tight-bottom pb-desk-margin-base-bottom',
      ],
      normalFont: ['text-body-small-regular font-body-small-regular leading-body-small-regular'],
      navigationOpen: [
        'z-20 py-desk-padding-tight-top px-desk-global-grid-margin mt-[36px] w-full left-0 bg-color-scale-1-white shadow-lg',
      ],
      liWrapper: [
        'w-full lg:w-fit lg:px-desk-padding-micro-x font-bold text-sm lg:py-desk-padding-micro-y border-b-[1px] lg:border-none',
      ],
      navigationLink: [
        'hover:no-underline lg:text-color-text-white lg:hover:text-color-text-black lg:relative',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      navigationClose: ['hidden'],
      navigationAfter: [
        'after:bg-color-background-white after:mt-2 after:absolute after:top-full after:border-[15px] after:border-color-border-white after:left-[50%] after:-translate-x-[50%] after:rotate-45',
      ],
      linkWrapper: [
        'hover:no-underline',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      ulWrapper: [
        'w-full justify-between lg:px-0 lg:py-1 px-mob-global-grid-margin py-mob-padding-micro-y lg:p-0 lg:col-span-4 border-b-[1px] border-color-border-mid lg:border-0 lg:items-center lg:justify-center text-body-large-regular leading-body-large-regular',
      ],
      h4: [
        'text-heading-desk-small-bold font-heading-desk-small-bold leading-heading-desk-small-bold font-primary',
      ],
      fontMedium: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
      ],
      flexGrid: ['items-center w-full justify-between lg:items-start'],
      flexCol: ['flex-col'],
      flexRow: [''],
      mobSubWrapper: [
        'items-start gap-mob-space-between-tight-horizontal !justify-normal px-mob-global-spacing-spacing-4 py-mob-global-spacing-spacing-3 border-b-[1px] border-color-border-mid',
      ],
      iconWrapper: ['lg:flex lg:justify-between lg:pb-[25px]'],
      searchwrapper: [
        'lg:py-desk-global-spacing-spacing-1 hidden lg:block px-desk-global-spacing-spacing-2 rounded-desk-global-radius-small placeholder:text-sm w-full',
      ],
      deskHidden: ['lg:hidden gap-mob-space-between-tight-horizontal'],
      leading39: ['leading-[39px]'],
      desktopHeading: ['text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold'],
      desktopIcon: ['!justify-normal gap-desk-space-between-base-vertical'],
      //*mobile utility
      mobUtilityNav: ['lg:hidden fixed w-full bottom-0 z-20'],
      utilityLi: ['text-color-text-white cursor-pointer'],
      utilityOpen: [
        'px-mob-padding-micro-x shadow-menuCard py-mob-padding-tight-top flex-col -z-10 top bg-white w-full h-fit overflow-hidden  text-color-text-black left-0 transition-all duration-1000',
      ],
      utilityImageMaxWidth: ['max-w-10'],
      utilityItem: ['flex-col w-max cursor-pointer'],
      utilityLiOpen: ['py-3 border-b-[1px] border-color-border-mid'],
      utilityUl: [
        'bg-color-brand-primary-1-base py-mob-padding-micro-y px-mob-global-grid-margin bottom-utility-nav',
      ],
      utilityImage: ['left-1/2 -translate-x-1/2'],
      utilityLogo: ['max-w-[50%] lg:max-w-[55%] xl:max-w-[95%] 2xl:max-w-full'],
      utilityFont: [
        'text-[12px] xl:text-body-small-regular leading-body-small-bold font-body-xSmall-bold',
      ],
      utilitLinkText: ['cursor-normal text-body-small-regular !border-0'],
      itemGap: ['gap-desk-space-between-micro-vertical'],
      leftUtilityText: ['lg:text-body-small-regular !flex-row !self-end'],
      storeLocatorText: [''],
      bodySmallBold: ['text-body-small-bold', 'font-body-small-bold', 'leading-body-small-bold'],
      //*desktop utility
      deskUtilityWrapper: [
        'hidden lg:flex py-desk-padding-micro-y lg:px-desk-padding-micro-x px-mob-padding-micro-x',
      ],
      itemWrapper: ['xl:px-[20px] xl:pt-[12px] px-[5px] flex-col items-center top-level-parent'],
      deskUtilityOpen: [
        'flex-col top-full rounded-desk-global-radius-medium shadow-menuCard mt-2 bg-white z-[950] min-w-[378px] px-desk-padding-micro-x py-desk-padding-tight-y',
      ],
      miniCartUtilityOpen: [
        'flex-col top-0 rounded-desk-global-radius-medium shadow-menuCard mt-2 bg-white z-[950] lg:min-w-[378px] w-full md:w-fit md:max-w-[378px] px-desk-padding-micro-x py-desk-padding-micro-y mini-cart-utility-open',
      ],
      img: ['w-auto'],
      utlityServiceHeding: ['text-body-large-bold font-body-large-bold !border-color-border-brand'],
      utlitiCloseIcon: ['top-4 right-4 w-6'],
      //*Store Locator
      storeLocator: [
        'block lg:hidden gap-mob-space-between-micro-horizontal p-mob-global-spacing-spacing-4 text-body-xSmall-regular',
      ],
      numberOfProduct: [
        'bg-color-brand-primary-1-base',
        'rounded-[200px]',
        'h-[16px]',
        'w-[20px]',
        'absolute',
        'right-0',
        'top-0',
        'flex',
        'justify-center',
        'items-center',
        'font-body-small-regular',
        'text-body-small-regular',
        'leading-body-small-regular',
        'text-white', // Token is not there in Figma, need to get replaced with token

        // 'px-6',
      ],
      numberOfMobProduct: [
        'bg-color-background-white',
        'rounded-[200px]',
        'h-[16px]',
        'w-[20px]',
        'absolute',
        'right-[3px]',
        'top-[8px]',
        'flex',
        'justify-center',
        'items-center',
        'font-body-small-regular',
        'text-body-small-regular',
        'leading-body-small-regular',
        'text-color-text-brand-1',

        // 'px-6',
      ],
      miniCartCloseBtn: ['absolute', 'right-0'],
      miniCartCloseBtnIcon: ['[&>svg>*>*]:fill-color-brand-primary-1-base', 'cursor-pointer'],
      uponSearchTrigger: [''],
      shopAllLink: [
        'text-color-brand-primary-1-base',
        'relative',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'hover:text-color-brand-tertiary-3-base',
        'underline',
        'ml-[5px]',
      ],
      bodyText: ['text-body-large-bold', 'font-body-large-bold', ' leading-body-large-bold'],
    },
    compoundSlots: [
      {
        slots: [
          'deskUtilityOpen',
          'miniCartUtilityOpen',
          'liWrapper',
          'ulWrapper',
          'flexGrid',
          'itemWrapper',
          'mobUtilityNav',
          'utilityOpen',
          'utlitiCloseIcon',
          'flexCol',
          'flexRow',
          'flexEnd',
          'itemsEnd',
        ],
        class: ['flex'],
      },
      {
        slots: ['justifyBetween', 'justifyCenter'],
        class: ['flex items-center w-full'],
      },
      {
        slots: ['utilityOpen', 'bodyRegular'],
        class: ['text-body-small-regular font-normal'],
      },
      {
        slots: ['leftUtilityText', 'storeLocatorText'],
        class: ['text-body-xSmall-regular'],
      },
      {
        slots: [
          'navigationOpen',
          'utilityOpen',
          'utilityOpen',
          'utilityImage',
          'deskUtilityOpen',
          'utlitiCloseIcon',
        ],
        class: ['absolute'],
      },

      {
        slots: ['desktopWrapper', 'relative'],
        class: ['relative'],
      },
      {
        slots: ['mobRightWrapper', 'mobLeftWrapper'],
        class: ['flex justify-between gap-mob-space-between-tight-horizontal items-center'],
      },
    ],
    variants: {
      isShown: {
        false: {
          mobileWrapper: ['translate-y-full'],
        },
        true: {
          mobileWrapper: [''],
        },
      },
      device: {
        mobile: {
          mobRightWrapper: ['flex-grow flex-shrink-0 basis-0 !w-full'],
        },
        desktop: {
          // mobRightWrapper: ['flex-grow-0 flex-shrink basis-auto w-fit'],
        },
      },
      turnSearchOn: {
        true: {
          uponSearchTrigger: ['!w-fit'],
        },
        false: {
          uponSearchTrigger: ['w-full'],
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
