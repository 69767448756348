import { IDropdownItem, OptionFieldProps } from './models';
import { useRouter } from 'next/router';
import { ComponentType, FC, useEffect, useMemo, useState } from 'react';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useBreakpoints } from 'src/utils/breakpoints';
import { evaluateRules } from './evaluateRules/evaluateRules';
import { useOcSelector } from 'src/redux/ocStore';
import dynamic from 'next/dynamic';
import { PSP } from 'models/PetSuppliesPlus.Model';

import { ComponentProps } from 'lib/component-props';
import { PersonalizationEditorType } from './components/PersonalizationEditor';

// const PersonalizationEditor = dynamic(() => import('./components/PersonalizationEditor'), {
export type PersonalizationProps =
  PSP.Sitecore.templates.PetSuppliesPlus.Base.Fields.IPersonalization &
    ComponentProps & { id?: string };
const withPersonalization = <P extends PersonalizationProps>(
  WrappedComponent: ComponentType<P>
): FC<P> => {
  const WithPersonalization: FC<P> = (props: P) => {
    const isEE = useExperienceEditor();

    // useState with default value to prevent hydration error
    const [isDevEditor, setIsDevEditor] = useState(false);
    useEffect(() => {
      // temporary way of viewing the experience editor while developing locally (set isEditor to true in localStorage)
      const isDevEditor = localStorage.getItem('isEditor') === 'true';
      setIsDevEditor(isDevEditor);
    }, []);

    const isEditor = isDevEditor || isEE;
    const { isMobile } = useBreakpoints();
    const { query, asPath } = useRouter();
    const goalId = query.sc_trk?.toString() || '';
    const stringifiedContentItems = JSON.stringify(props.fields?.ContentItems || []);
    const selectedStore = useOcSelector((state) => state?.storeReducer?.selectedStore);

    const storeId = selectedStore.storeId;
    const user = useOcSelector((state) => state?.ocUser?.user);
    const isBopisEnabled = useMemo(() => selectedStore.BOPIS === true, [selectedStore?.BOPIS]);
    const isDfsEnabled = useMemo(() => selectedStore?.DFS === true, [selectedStore?.DFS]);
    const isFranchise = useMemo(() => selectedStore?.StoreType === 2, [selectedStore?.StoreType]);
    const isCorporate = useMemo(() => selectedStore?.StoreType === 1, [selectedStore?.StoreType]);
    const hasOnlineBooking = useMemo(
      () => selectedStore?.OnlineBooking === true,
      [selectedStore?.OnlineBooking]
    );
    const storeServices = useMemo(
      () => selectedStore?.StoreServices || [],
      [selectedStore?.StoreServices]
    );
    const userHasAcceptedLoyaltyTermsOfUse = useMemo(
      () => user?.xp?.LoyaltyAccepted === true,
      [user?.xp?.LoyaltyAccepted]
    );
    const userPetSpecies = useMemo(
      () => (user?.xp?.pets ? Object.keys(user.xp.pets) : []),
      [user?.xp?.pets]
    );

    const matchedRule = useMemo(
      () =>
        !props?.fields?.Rules?.value || isEditor
          ? null
          : evaluateRules(JSON.parse(props?.fields?.Rules?.value), {
              storeId,
              path: asPath,
              goalId,
              isMobile,
              isBopisEnabled,
              isDfsEnabled,
              isFranchise,
              isCorporate,
              storeServices,
              hasOnlineBooking,
              userHasAcceptedLoyaltyTermsOfUse,
              userPetSpecies,
            }),
      [
        asPath,
        goalId,
        hasOnlineBooking,
        isBopisEnabled,
        isCorporate,
        isDfsEnabled,
        isEditor,
        isFranchise,
        isMobile,
        props?.fields?.Rules?.value,
        storeId,
        storeServices,
        userHasAcceptedLoyaltyTermsOfUse,
        userPetSpecies,
      ]
    );

    const matchedRuleContent = useMemo(() => {
      if (matchedRule) {
        return JSON.parse(stringifiedContentItems).find(
          // TODO: remove type 'any' once we have the generated type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => {
            return item.id.replace(/-/g, '').toUpperCase() === matchedRule.contentItemId;
          }
        );
      }
      return null;
    }, [matchedRule, stringifiedContentItems]);

    // useMemo is needed to not continuously re-render
    const PersonalizationEditor = useMemo(
      () =>
        // Only import if we're editing.  This prevents importing a whole chain of components
        // that are not needed for end-users
        isEditor
          ? (dynamic(() => import('./components/PersonalizationEditor'), {
              ssr: true,
            }) as PersonalizationEditorType)
          : () => <></>,
      [isEditor]
    );

    if (isEditor) {
      return <PersonalizationEditor<P> props={props} WrappedComponent={WrappedComponent} />;
    }

    if (matchedRule?.show) {
      if (matchedRuleContent?.fields) {
        return <WrappedComponent {...props} fields={matchedRuleContent.fields} />;
      }
    } else if (matchedRule && !matchedRule.show) {
      return <></>;
    }

    if (props?.fields?.HideDefaultContent?.value === true) {
      return <></>;
    } else {
      return <WrappedComponent {...props} />;
    }
  };

  WithPersonalization.displayName = 'WithPersonalization';

  return WithPersonalization;
};

export default withPersonalization;

export const toOption = (item: IDropdownItem): OptionFieldProps => ({
  value: { value: item?.value?.value },
  text: { value: item.label?.value },
});
