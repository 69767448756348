import GenericStoreLocatorWidget from 'components/StoreLocatorHelpers/GenericStoreLocator';
import { StoreLocatorProps } from 'components/StoreLocatorHelpers/GenericStoreLocator';
import ComponentContext from 'lib/context/ComponentContext';
import React, { useContext, useEffect } from 'react';

const StoreLocatorGlobalModal = ({ fields, params, rendering }: StoreLocatorProps) => {
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);

  useEffect(() => {
    setcomponentContextData({ ...componentContextData, showStoreLocatorGlobalModal: false });
  }, []);

  //if no data then returned empty fragment.env
  if (fields === undefined || fields === null) return <></>;
  return (
    <>
      {componentContextData?.showStoreLocatorGlobalModal && (
        <div className="StoreLocatorGlobalModal ">
          <GenericStoreLocatorWidget
            rfkId={fields?.rfkid?.value || 'rfk_Store_Locator_Geo_Location'}
            isModal={true}
            fields={fields}
            params={params}
            rendering={rendering}
          />
        </div>
      )}
    </>
  );
};

export default StoreLocatorGlobalModal;
