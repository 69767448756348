//global
import React, { useContext } from 'react';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import ComponentContext from 'lib/context/ComponentContext';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useOcSelector } from 'src/redux/ocStore';
import { useTheme } from 'lib/context/ThemeContext';
import { closedText } from 'src/helpers/Constants';
import { changeMyStoreTailwindVariant } from 'tailwindVariants/components/changeMyStoreTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';
import clsx from 'clsx';
import { navigationTailwindVariant } from 'tailwindVariants/components/navigationTailwindVariant';
// import { useSelector } from 'react-redux';
//type
export type ChangeMyStoreProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.ChangeMyStoreWidget.Fields.ChangeMyStoreWidget;
//component variants
export type theme = 'psp' | 'wnw';

//main component
const ChangeMyStore: React.FC<ChangeMyStoreProps> = ({ fields, rendering }): JSX.Element => {
  const { themeName } = useTheme();
  const {
    base,
    storeName,
    storeAddress,
    timingWrapper,
    storeNumber,
    storeTiming,
    cta,
    buttonWrapper,
    icon,
    changeMyStoreTitle,
    changeMyStoreLinks,
    changeMyStoreV2Wrapper,
  } = changeMyStoreTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
    theme: themeName,
  });

  const { utilityLiOpen, utilitLinkText } = navigationTailwindVariant();
  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const router = useRouter();
  const now = new Date();
  const day = now.getDay();

  //Store Data
  const storeData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  //handling keydown event for accessibility
  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter') {
      setHeaderContextData({ ...headerContextData, isChangeStoreOpen: false });
    }
  };

  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const myStoreServices = myStoreData?.services;

  const myStoreServicesArray = myStoreServices ? myStoreServices.split('|') : [];
  const storeServices = useGraphQlDataContext();

  const selectedService = myStoreServicesArray?.map(
    (storeServiceId) =>
      storeServices &&
      storeServices?.storeServices?.find(
        (service) => service?.serviceId?.value?.toString() === storeServiceId
      )
  );
  const secondaryServicesToken = '{secondary-services}';
  const navigationServices = selectedService?.filter(
    (service) => !service?.hideInNavigation?.value
  );

  const secondaryServices = selectedService?.filter(
    (service) => service?.isSecondaryService?.value
  );

  const tabIndex = headerContextData?.isChangeStoreOpen ? 0 : -1;
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      className={base({
        className: `${headerContextData?.isChangeStoreOpen ? 'max-h-[1000px]' : '!max-h-0 !py-0'}`,
      })}
    >
      <IconHelper
        tabIndex={tabIndex}
        icon="close"
        className={icon()}
        onClick={() => {
          setHeaderContextData({ ...headerContextData, isChangeStoreOpen: false });
        }}
        onKeyDown={handleOnKeyDown}
      />
      <div id={'store-detial'}>
        <p className={storeName()}>{storeData?.storeName}</p>
        <div className="storeAddress">
          <p className={storeAddress()}>{storeData?.street1}</p>
          <p className={storeAddress({ className: '!mt-0' })}>
            {storeData?.city}, {storeData?.state} {storeData?.zip}
          </p>
        </div>
        <Link
          aria-label="phone number link"
          href={`tel:${storeData?.phone}`}
          className={storeNumber()}
          tabIndex={tabIndex}
        >
          {storeData?.phone}
        </Link>
        <div className={timingWrapper()}>
          {storeData?.hours &&
          Object?.values(storeData?.hours)?.[day] &&
          Object?.values(storeData?.hours)?.[day] === closedText ? (
            <span
              className={storeTiming({
                className: '!font-body-small-bold',
              })}
            >
              {storeData?.hours && Object?.values(storeData?.hours)?.[day]?.toUpperCase()}
            </span>
          ) : (
            <React.Fragment>
              <RichTextHelper field={fields?.storeTimingLabel} className={storeTiming()} />
              <span className={storeTiming()}>
                {storeData?.hours && (Object?.values(storeData?.hours)?.[day] as string)}
              </span>
            </React.Fragment>
          )}
        </div>
      </div>
      <div id={'store-change'} className={buttonWrapper()}>
        <button
          tabIndex={tabIndex}
          aria-label="store-change"
          className={cta()}
          onClick={() => {
            setHeaderContextData({
              ...headerContextData,
              isChangeStoreOpen: false,
            });
            setcomponentContextData({
              ...componentContextData,
              showStoreLocatorGlobalModal: true,
            });
          }}
        >
          <TextHelper field={fields?.changeStoreLabel} />
        </button>
        <LinkHelper
          tabIndex={tabIndex}
          onClick={(e) => {
            e.preventDefault();
            router.push(
              `/store/${storeData?.state?.toLowerCase()}/${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}-${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}`?.replaceAll(
                ' ',
                '-'
              )
            );
            setHeaderContextData({ ...headerContextData, isChangeStoreOpen: false });
          }}
          field={{
            value: {
              href: `/store/${storeData?.state?.toLowerCase()}/${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}-${storeData?.city?.toLowerCase()}/${storeData?.storeId?.toLowerCase()}`?.replaceAll(
                ' ',
                '-'
              ),
              text: fields?.storeDetailsCTA?.value?.text,
            },
          }}
          className={cta()}
        />
      </div>
      {fields?.isv2?.value && (
        <div className={changeMyStoreV2Wrapper()}>
          <div>
            {fields?.serviceText?.value && (
              <p className={changeMyStoreTitle()}>{fields?.serviceText?.value}</p>
            )}
          </div>
          {navigationServices?.map((service, index) => (
            <LinkHelper
              field={{
                value: {
                  href: service?.link?.value?.href,
                  text: service?.name?.value,
                },
              }}
              tabIndex={tabIndex}
              className={changeMyStoreLinks()}
              key={index}
              onClick={() => {
                setHeaderContextData({
                  ...headerContextData,
                  isChangeStoreOpen: false,
                });
              }}
            />
          ))}

          <div className={clsx(utilityLiOpen(), utilitLinkText())}>
            {fields?.storeFacility?.value?.includes(secondaryServicesToken) &&
            secondaryServices?.length !== 0 ? (
              <span>{fields?.storeFacility?.value?.replace(secondaryServicesToken, '')}</span>
            ) : (
              <span>{fields?.storeFacility?.value}</span>
            )}

            {fields?.storeFacility?.value?.includes(secondaryServicesToken) &&
              secondaryServices?.map((service, index, array) => {
                if (secondaryServices.length === 2 && index < array.length - 1) {
                  return service?.name?.value + ' and ';
                }
                if (secondaryServices.length > 2 && index < array.length - 1) {
                  if (index < array.length - 2) {
                    return service?.name?.value + ', ';
                  } else {
                    return service?.name?.value + ' and ';
                  }
                }
                return service?.name?.value;
              })}
          </div>
        </div>
      )}

      <Placeholder name="change-store-personalised" rendering={rendering} />
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<ChangeMyStoreProps>(ChangeMyStore);
