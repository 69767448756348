import {
  Field,
  Text,
  RichText,
  withDatasourceCheck,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { Field as FormikField, Form, Formik, FormikProps } from 'formik';
// import { PSP } from 'models/PetSuppliesPlus.Model';

import { ThemeUpperCase } from 'src/helpers/Constants';
import { apiRequest } from 'src/utils/apiWrapper';
import { useRef, useState } from 'react';
import clsx from 'clsx';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import Loader from 'components/Loader/Loader';
import { triggerSubmitEvent } from 'src/helpers/formHelper';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import { newsletterModalTailwindVariant } from 'tailwindVariants/components/newsletterModalTailwindVariant';
import { newsletterTailwindVariant } from 'tailwindVariants/components/newsletterTailwindVariant';
import { orderPromotionApi } from 'src/utils/nextApiConfig';

export type Theme = 'psp' | 'wnw';

export type NewsLetterProps = ComponentProps & {
  fields: {
    data: {
      item: {
        id: string;
        title?: Field<string>;
        emailAddressPlaceholder?: Field<string>;
        emailAddressRequiredErrorMessage?: Field<string>;
        emailAddressInvalidErrorMessage?: Field<string>;
        buttonLabel?: Field<string>;
        modalTitle?: Field<string>;
      };
    };
  };
};
// PSP.Sitecore.templates.PetSuppliesPlus.Newsletter.Fields.Newsletter; //TODO: Leprechaun from BED

const initialValues = {
  email: '',
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const NewsLetter = ({ fields, params }: NewsLetterProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [responseMesssage, setResponseMesssage] = useState('');
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [showRed, setShowRed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const sitename = useSitecoreContext().sitecoreContext.site?.name?.toUpperCase() as ThemeUpperCase;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const innerFormRef: React.RefObject<FormikProps<any>> = useRef(null);
  const formRef = useRef<HTMLFormElement>(null);
  const { base, title, input, wrapper, button, loaderButton } = newsletterTailwindVariant({
    color: useSitecoreContext().sitecoreContext.site?.name?.toUpperCase() === 'PSP',
    size: {
      initial: 'small',
      lg: 'large',
    },
  });

  const defaultModalTv = newsletterModalTailwindVariant({
    size: {
      initial: 'small',
      lg: 'large',
    },
  });

  // Function to reset the email input field
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resetEmailField = () => {
    if (innerFormRef.current) {
      const formikProps = innerFormRef.current;
      if (formikProps.resetForm) {
        formikProps.resetForm(); // Reset the form using Formik's resetForm method
      }
    }
  };
  const token = useAuthToken();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promotionalSignUp = async (values: any) => {
    const emailInput = values?.email;
    if (!emailInput) {
      return; // to avoid unnecessary targets
    }
    setLoadSpinner(true);
    const signForPromotion = orderPromotionApi?.promotionSignup;
    const headersData = {
      site: sitename, // for now, only PSP would work, WNW integration is left from OC Marketplace
      requesturl: window.location.origin,
      Authorization: token,
    };
    const postData = {
      Email: emailInput,
      IsPSP: sitename == 'PSP' ? true : false,
      DatasourceId: fields?.data?.item?.id,
    };
    const options = { method: 'POST', headers: headersData, data: postData };

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await apiRequest(signForPromotion, options);
      if (response && response?.Message) {
        setResponseMesssage(response?.Message);
        setIsModalOpen(true);
        setLoadSpinner(false);
        document.body.classList.add('modal-open');
      }
    } catch (error) {
      console.error('API Error:', error);
      setLoadSpinner(false);
    }
  };

  // Handle the form submission logic here
  const handleNewsLetterSignup = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _values: any,
    actions: { setFieldError: (arg0: string, arg1: string | undefined) => void }
  ) => {
    const emailInput = _values?.email;
    if (!emailInput) {
      actions.setFieldError('email', fields?.data?.item?.emailAddressRequiredErrorMessage?.value);
      setShowRed(true);
      setErrorMessage(fields?.data?.item?.emailAddressRequiredErrorMessage?.value || 'Is required');
      return;
    }

    // Validate email format
    const validationpattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const regex = new RegExp(validationpattern);

    if (!regex.test(emailInput)) {
      actions.setFieldError('email', fields?.data?.item?.emailAddressInvalidErrorMessage?.value);
      setShowRed(true);
      setErrorMessage(
        fields?.data?.item?.emailAddressInvalidErrorMessage?.value || 'Field not valid'
      );
      return;
    }
    await promotionalSignUp(_values);
    triggerSubmitEvent(formRef);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleModalClose = () => {
    resetEmailField(); // Reset the email input field
    setIsModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  return (
    <>
      <div className={base({ className: params?.Styles ?? '' })} id="newsletter">
        {fields?.data?.item?.title && (
          <RichTextHelper tag="p" className={clsx(title())} field={fields?.data?.item?.title} />
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={handleNewsLetterSignup}
          innerRef={innerFormRef}
        >
          {(formikProps) => (
            <Form ref={formRef}>
              {showRed && formikProps.errors.email && (
                <p className="text-system-red">{errorMessage}</p>
              )}

              <div className={wrapper()}>
                <FormikField
                  type="email"
                  className={input()}
                  id="email"
                  name="email"
                  aria-label="email"
                  placeholder={fields?.data?.item?.emailAddressPlaceholder?.value}
                />
                {!loadSpinner && (
                  <button aria-label="submit" className={button()} type="submit">
                    {fields?.data?.item?.buttonLabel?.value}
                  </button>
                )}
                {loadSpinner && (
                  <span className={clsx(loaderButton(), 'flex items-center')}>
                    <Loader />
                  </span>
                )}
              </div>
            </Form>
          )}
        </Formik>

        <ModalWrapper
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          onCloseClick={handleModalClose}
          showModal={isModalOpen}
          customPopup={true}
          popupSpacing="py-mob-space-between-loose-horizontal px-mob-padding-tight-x lg:py-desk-padding-tight-y lg:px-desk-padding-tight-x shadow-privacyShadow modal-open"
          popupBG="bg-transparent"
          additionalClassForPopUpModal={`${
            params?.Styles ?? ''
          } !item-start !h-fit translate-y-0  animate-[topAnimation_0.3s_ease-in-out]`}
          popupWidth={'!w-[835px]'}
        >
          <div className={defaultModalTv?.contentWrapperM()}>
            <Text
              tag="p"
              className={defaultModalTv?.titleM()}
              field={fields?.data?.item?.modalTitle}
            />

            <RichText
              className={defaultModalTv?.descriptionM()}
              field={{ value: responseMesssage }}
            />
          </div>
        </ModalWrapper>
      </div>
    </>
  );
};

export default withDatasourceCheck()<NewsLetterProps>(NewsLetter);
